import React, { useState } from 'react';
import { Form } from 'antd';
import { ClaimValue } from '@types';
import PreviewModal from './Preview';
import ClaimForm from './ClaimForm';
import { useMediaQuery } from 'react-responsive';
import { Helmet } from 'react-helmet';

const Index: React.FC = () => {
	const [claimForm] = Form.useForm();
	const isMobile = useMediaQuery({ query: '(max-width: 1050px)' });

	const [visible, setVisible] = useState<boolean>(false);
	const [formValue, setFormValue] = useState<ClaimValue | undefined>(undefined);

	const onGeneratePdfHandler = () => {
		claimForm.validateFields().then((values) => {
			// remove all amount is 0
			const claim = values.claim.filter((claim: any) => claim.amount !== 0);
			values.claim = claim;
			setFormValue(values);
			setVisible(true);
		});
	};

	return (
		<>
			<Helmet>
				<title>Claim Form | Hung Wai</title>
			</Helmet>
			<div className="flex justify-center" style={{ marginTop: isMobile ? 66.5 : 0 }}>
				<div className="w-full max-w-[1000px] overflow-auto shadow-[rgba(100,100,111,0.2)_0_7px_29px_0] bg-white my-10 mx-5">
					<ClaimForm form={claimForm} onGeneratePdf={onGeneratePdfHandler} />
				</div>
				<PreviewModal visible={visible} setVisible={setVisible} value={formValue} />
			</div>
		</>
	);
};

export default Index;
