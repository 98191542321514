import React, { useState } from 'react';
import { Form, message } from 'antd';
import { LeaveValue } from '@types';
import PreviewModal from './Preview';
import LeaveForm from './LeaveForm';
import { useMediaQuery } from 'react-responsive';
import { Helmet } from 'react-helmet';

const Index: React.FC = () => {
	const [leaveForm] = Form.useForm();
	const isMobile = useMediaQuery({ query: '(max-width: 1050px)' });

	const [visible, setVisible] = useState<boolean>(false);
	const [formValue, setFormValue] = useState<LeaveValue | undefined>(undefined);

	const onGeneratePdfHandler = () => {
		leaveForm.validateFields().then((values) => {
			console.log(values);

			if (!values.leaveType) {
				message.error({
					content: 'Please select a leave type',
					key: 'leaveType',
				});
			}
			setFormValue(values);
			setVisible(true);
		});
	};

	return (
		<>
			<Helmet>
				<title>Leave Form | Hung Wai</title>
			</Helmet>
			<div className="flex justify-center" style={{ marginTop: isMobile ? 66.5 : 0 }}>
				<div className="w-full max-w-[1000px] overflow-auto shadow-[rgba(100,100,111,0.2)_0_7px_29px_0] bg-white my-10 mx-5">
					<LeaveForm form={leaveForm} onGeneratePdf={onGeneratePdfHandler} />
				</div>
				<PreviewModal visible={visible} setVisible={setVisible} value={formValue} />
			</div>
		</>
	);
};

export default Index;
