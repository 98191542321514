import { Col, DatePicker, Form, Input, InputNumber, Row } from 'antd';

const AnnualLeave: React.FC = () => {
	return (
		<div>
			<Row gutter={[16, 16]}>
				<Col xs={12} sm={6} md={6} lg={6} xl={6}>
					<Form.Item
						rules={[
							{ required: true },
							({ getFieldValue }) => ({
								validator(_, value) {
									if (value % 0.5 === 0) {
										return Promise.resolve();
									}
									return Promise.reject(new Error('Days must be the multiple of 0.5, ex: 0.5, 1.0, 1.5, 2.5'));
								},
							}),
						]}
						initialValue={0}
						label="Carry Forward"
						name="carryForward"
					>
						<InputNumber min={0} precision={1} addonAfter="Days" step={0.5} />
					</Form.Item>
				</Col>
				<Col xs={12} sm={6} md={6} lg={6} xl={6}>
					<Form.Item
						rules={[
							{ required: true },
							({ getFieldValue }) => ({
								validator(_, value) {
									if (value % 0.5 === 0) {
										return Promise.resolve();
									}
									return Promise.reject(new Error('Days must be the multiple of 0.5, ex: 0.5, 1.0, 1.5, 2.5'));
								},
							}),
						]}
						initialValue={0}
						label="Entitlement"
						name="entitlement"
					>
						<InputNumber min={0} precision={1} addonAfter="Days" step={0.5} />
					</Form.Item>
				</Col>
				<Col xs={12} sm={6} md={6} lg={6} xl={6}>
					<Form.Item
						rules={[
							{ required: true },
							({ getFieldValue }) => ({
								validator(_, value) {
									if (value % 0.5 === 0) {
										return Promise.resolve();
									}
									return Promise.reject(new Error('Days must be the multiple of 0.5, ex: 0.5, 1.0, 1.5, 2.5'));
								},
							}),
						]}
						initialValue={0}
						label="Applied"
						name="applied"
					>
						<InputNumber min={0} precision={1} addonAfter="Days" step={0.5} />
					</Form.Item>
				</Col>
				<Col xs={12} sm={6} md={6} lg={6} xl={6}>
					<Form.Item
						rules={[
							{ required: true },
							({ getFieldValue }) => ({
								validator(_, value) {
									if (value % 0.5 === 0) {
										return Promise.resolve();
									}
									return Promise.reject(new Error('Days must be the multiple of 0.5, ex: 0.5, 1.0, 1.5, 2.5'));
								},
							}),
						]}
						initialValue={0}
						label="Balance"
						name="balance"
					>
						<InputNumber min={0} precision={1} addonAfter="Days" step={0.5} />
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={[16, 16]}>
				<Col xs={24} sm={24} md={24} lg={12} xl={12}>
					<Form.Item label="Period" name="period" rules={[{ required: true }]}>
						<DatePicker.RangePicker className="w-full" format="DD MMMM YYYY" />
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={[16, 16]}>
				<Col xs={12} sm={6} md={6} lg={6} xl={6}>
					<Form.Item
						rules={[
							{ required: true },
							({ getFieldValue }) => ({
								validator(_, value) {
									if (value % 0.5 === 0) {
										return Promise.resolve();
									}
									return Promise.reject(new Error('Days must be the multiple of 0.5, ex: 0.5, 1.0, 1.5, 2.5'));
								},
							}),
						]}
						initialValue={0}
						label="Total Apply"
						name="totalApply"
					>
						<InputNumber min={0} precision={1} addonAfter="Days" />
					</Form.Item>
				</Col>
			</Row>
			<Form.Item rules={[{ required: true }]} label="Reason" name="reason">
				<Input.TextArea rows={4} />
			</Form.Item>
		</div>
	);
};

export default AnnualLeave;
