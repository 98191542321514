import { Claim } from '@types';
import { Button, DatePicker, Divider, Input, InputNumber, Space } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';

interface ClaimTableProps {
	value?: Claim[];
	onChange?: (value: Claim[]) => void;
}

const initialValue = {
	description: '',
	date: undefined,
	invoice: '',
	amount: 0,
} as Claim;

const ClaimTable: React.FC<ClaimTableProps> = ({ value = [initialValue], onChange }) => {
	const [claimValue, setClaimValue] = useState<Claim[]>(value);
	const [total, setTotal] = useState<number>(0);

	useEffect(() => {
		let total = 0;
		claimValue.forEach((claim) => {
			total += claim.amount;
		});
		setTotal(total);
	}, [claimValue]);

	useEffect(() => {
		onChange?.(claimValue);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [claimValue]);

	useEffect(() => {
		setClaimValue(value);
	}, [value]);

	const onAddNewRowHandler = () => {
		const newClaimValue = [...claimValue];
		newClaimValue.push(initialValue);
		setClaimValue(newClaimValue);
		onChange?.(newClaimValue);
	};

	const onChangeValueHandler = (index: number, key: string, value: any) => {
		const newClaimValue = [...claimValue];

		let newValue = value;
		if (key === 'amount' && !value) {
			newValue = 0;
		}

		const updatedRow = {
			...newClaimValue[index], // copy existing object properties
			[key]: newValue, // update specified property
		};

		newClaimValue[index] = updatedRow; // replace object at specified index

		setClaimValue(newClaimValue);
	};

	const onRemoveRowHandler = (index: number) => {
		const newClaimValue = [...claimValue];
		newClaimValue.splice(index, 1);

		if (newClaimValue.length === 0) {
			newClaimValue.push(initialValue);
		}

		setClaimValue(newClaimValue);
	};

	const onRemoveAllRowHandler = () => {
		setClaimValue([initialValue]);
	};

	return (
		<div>
			<div className="flex justify-end mb-3">
				<Space>
					<Button type="primary" icon={<DeleteOutlined />} danger onClick={onRemoveAllRowHandler}>
						Remove All
					</Button>
					<Button type="primary" icon={<PlusOutlined />} onClick={onAddNewRowHandler}>
						Add
					</Button>
				</Space>
			</div>
			<div id="claim_table">
				<div className="overflow-x-auto">
					<table className="w-full text-sm text-left text-gray-500 border-collapse min-w-[700px]">
						<thead className="text-xs text-gray-700 uppercase bg-gray-200">
							<tr>
								<th className="px-6 py-3 w-[10px]">No</th>
								<th className="px-6 py-3">Description & Purpose of Claim</th>
								<th className="px-6 py-3 w-[150px]">Date</th>
								<th className="px-6 py-3 w-[200px]">Invoice#</th>
								<th className="px-6 py-3 w-[150px]">Amount</th>
								<th className="px-6 py-3 w-[50px]"></th>
							</tr>
						</thead>
						<tbody>
							{claimValue.map((claim, index) => (
								<tr key={index}>
									<td className="p-2 text-center border border-black">{index + 1}</td>
									<td className="p-2 border border-black">
										<Input defaultValue="" value={claim.description} placeholder="Description..." onChange={(e) => onChangeValueHandler(index, 'description', e.target.value)} />
									</td>
									<td className="p-2 border border-black">
										<DatePicker defaultValue={undefined} value={claim?.date} onChange={(value) => onChangeValueHandler(index, 'date', value)} />
									</td>
									<td className="p-2 border border-black">
										<Input defaultValue="" value={claim.invoice} placeholder="invoice number..." onChange={(e) => onChangeValueHandler(index, 'invoice', e.target.value)} />
									</td>
									<td className="p-2 border border-black">
										<InputNumber
											addonBefore="RM"
											min={0}
											step={1}
											defaultValue={0}
											value={claim.amount}
											onChange={(value) => onChangeValueHandler(index, 'amount', value)}
											precision={2}
										/>
									</td>
									<td className="p-2 border border-black">
										<div>
											<Button type="primary" danger icon={<DeleteOutlined />} onClick={() => onRemoveRowHandler(index)} />
										</div>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>

				<Divider />
				<div className="flex justify-end">
					<span className="text-base font-bold">Total: RM {total.toFixed(2)}</span>
				</div>
			</div>
		</div>
	);
};

export default ClaimTable;
